@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --indent-size: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


img.logo {
  width: auto;
  max-height: 1.4em;
  margin: 1em auto;
}

.indent-0 { margin-left: 0; }
.indent-1 { margin-left: 20px; }
.indent-2 { margin-left: 40px; }
.indent-3 { margin-left: 60px; }
.indent-4 { margin-left: 80px; }
/* Add more levels as needed */


.indented {
  margin-left: calc(var(--indent-size) * var(--level, 0));
}